/* eslint-disable @typescript-eslint/no-shadow */
import { Grid, MenuItem, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { useMemo, useRef, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import Donut from '~/components/common/Chart/Donut';
import { IDonutItem } from '~/components/common/Chart/Donut/Donut';
import Rating from '~/components/common/Chart/Rating';
import { IRatingItem } from '~/components/common/Chart/Rating/Rating';
import Summary from '~/components/common/Chart/Summary';
import { ISummaryItem } from '~/components/common/Chart/Summary/Summary';
import Tasks from '~/components/common/Chart/Tasks';
import TimeSlots from '~/components/common/Chart/TimeSlots';
import { ITaskItem } from '~/components/common/Chart/Tasks/Tasks';
import { Select } from '~/components/common/Select';
import { TIMEZONE_SETTING } from '~/constants/adminOptions';
import { DEFAULT_TIMEZONE } from '~/constants/common';
import { useAuth } from '~/providers/AuthProvider';
import { useGetDashboardQuery } from '~/services/api/dashboard';
import themes from '~/themes';
import { getHourAndMinLabel, getHourMinSecLabel } from '~/utils/common';
import { IOptionItem } from '~/models/common';
import { KeyboardArrowRight } from '@mui/icons-material';
import Button from '~/components/common/Button';
import ExportIcon from '~/assets/images/icons/export.svg';
import { flatMap, map } from 'lodash';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { HitMapProvider } from './context/HitMapContext';
import { DropOffHitMap } from './components/DropOffHitMap';
import { DriverTable } from './components/DriverTable';
import { NewDropOffHitMap } from './components/DropOffHitMap/NewDropOffHitMap';
import DateRangePicker from './components/DateRangePicker';

interface IDashboard {}

const driverUtilColors = ['#34BF4B', '#DDE3EE'];

const taskAssignColors = ['#34BF4B', '#FF5C1B'];

const DateOption: IOptionItem[] = [
  { value: 1, label: 'Today' },
  { value: 7, label: 'Last 7 days' },
  { value: 14, label: 'Last 14 days' },
  { value: 30, label: 'Last 30 days' },
  { value: 'custom', label: 'Select custom date' },
];

const randomIntFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const createWeekTimeSlots = () => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = (i + 1) % 24;
    return `${String(hour).padStart(2, '0')}:00`;
  });

  const weekTimeSlots = {};

  days.forEach((day) => {
    weekTimeSlots[day] = hours.reduce((acc, hour) => {
      acc[hour] = 0;
      return acc;
    }, {});
  });

  return weekTimeSlots;
};

const Dashboard: React.FC<IDashboard> = () => {
  const isNewHeatMap = useFeatureIsOn('cf-show-new-heat-map');
  const [selectedDate, setSelectedDate] = useState<any>(
    DateOption[2].value as any,
  );

  const { account } = useAuth();
  const timezone = useMemo(() => {
    if (account.timezone) {
      if (account.timezone === TIMEZONE_SETTING.AUTO_DETECT) {
        let tz = momentTz.tz.guess();
        if (tz === 'Asia/Saigon') {
          tz = 'Asia/Ho_Chi_Minh';
        }
        return tz;
      }
      return account.timezone;
    }
    return DEFAULT_TIMEZONE;
  }, [account.org]);

  const [endDate, setEndDate] = useState<any>(moment());
  const [selectRangeDate, setSelectRangeDate] = useState(false);
  const exportPageOneRef = useRef<any>();
  const exportPageTwoRef = useRef<any>();
  const [loadingExport, setLoadingExport] = useState(false);
  const [isSelectOption, setIsSelectOption] = useState(true);

  const date_gte = useMemo(
    () =>
      selectRangeDate && !isSelectOption
        ? endDate
            ?.clone()
            .subtract(selectedDate - 1, 'days')
            .format('YYYY-MM-DD')
        : endDate
            ?.clone()
            .subtract(selectedDate === 1 ? 0 : selectedDate, 'days')
            .format('YYYY-MM-DD'),
    [selectRangeDate, endDate, selectedDate],
  );

  const anchorElRef = useRef(null);
  const [isSelectCustom, setIsSelectCustom] = useState(false);

  const { data, isFetching: isLoadingDashboard } = useGetDashboardQuery({
    params: {
      length: selectedDate,
      date_gte,
      timezone,
    },
    enabled: !!timezone,
  });

  const handleExport = async () => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'mm', 'a4');
    setLoadingExport(true);

    const captureElementAsImage = async (element) => {
      element.style.right = '-99999px';
      element.style.opacity = 1;
      const canvas = await html2canvas(element, { useCORS: true });
      element.style.opacity = 0;
      element.style.right = 0;
      return canvas.toDataURL('image/jpeg');
    };

    const [imgData1, imgData2] = await Promise.all([
      captureElementAsImage(exportPageOneRef.current),
      captureElementAsImage(exportPageTwoRef.current),
    ]);

    const imgWidth = 210;
    const imgHeight1 =
      (exportPageOneRef.current.offsetHeight * imgWidth) /
      exportPageOneRef.current.offsetWidth;
    const imgHeight2 =
      (exportPageTwoRef.current.offsetHeight * imgWidth) /
      exportPageTwoRef.current.offsetWidth;

    pdf.addImage(imgData1, 'JPEG', 0, 0, imgWidth, imgHeight1);
    pdf.addPage();
    pdf.addImage(imgData2, 'JPEG', 0, 0, imgWidth, imgHeight2);

    pdf.save(`Insight_report_${moment().format('YYYYMMDD')}.pdf`);
    setLoadingExport(false);
  };

  const isLoading = !timezone || isLoadingDashboard;

  const summaryData: ISummaryItem[] = [
    {
      title: 'Completed tasks',
      count: data?.completed_tasks || 0,
      trend: data?.completed_tasks_trend?.difference || 0,
    },
    {
      title: 'On time tasks',
      count: data?.on_time_tasks || 0,
      trend: data?.on_time_tasks_trend?.difference || 0,
    },
    {
      title: 'Late tasks',
      count: data?.late_tasks || 0,
      trend: data?.late_tasks_trend?.difference || 0,
    },
    {
      title: 'Cancelled tasks',
      count: data?.cancelled_tasks || 0,
      trend: data?.cancelled_tasks_trend?.difference || 0,
    },
    {
      title: 'Returned tasks',
      count: data?.returned_tasks || 0,
      trend: data?.returned_tasks_trend?.difference || 0,
    },
  ];

  const emptyTaskList = useMemo(
    () =>
      selectRangeDate
        ? Array(selectedDate)
            .fill(0)
            .reduce(
              (rs, item, index) =>
                rs.concat([
                  {
                    date: endDate
                      ?.clone()
                      ?.add(1, 'day')
                      .subtract(selectedDate - index, 'day')
                      .format('YYYY/MM/DD'),
                    tooltipDate: endDate
                      ?.clone()
                      ?.add(1, 'day')
                      .subtract(selectedDate - index, 'day')
                      .format('YYYY-MM-DD'),
                    value: randomIntFromInterval(15, 25),
                    type: 'On time tasks',
                  },
                  {
                    date: endDate
                      ?.clone()
                      ?.add(1, 'day')
                      .subtract(selectedDate - index, 'day')
                      .format('YYYY/MM/DD'),
                    tooltipDate: endDate
                      ?.clone()
                      ?.add(1, 'day')
                      .subtract(selectedDate - index, 'day')
                      .format('YYYY-MM-DD'),
                    value: randomIntFromInterval(15, 25),
                    type: 'Late tasks',
                  },
                ]),
              [],
            )
        : Array(selectedDate)
            .fill(0)
            .reduce(
              (rs, item, index) =>
                rs.concat([
                  {
                    date: endDate
                      ?.clone()
                      .subtract(selectedDate - index, 'day')
                      .format('YYYY/MM/DD'),
                    tooltipDate: endDate
                      ?.clone()
                      .subtract(selectedDate - index, 'day')
                      .format('YYYY-MM-DD'),
                    value: randomIntFromInterval(15, 25),
                    type: 'On time tasks',
                  },
                  {
                    date: endDate
                      ?.clone()
                      .subtract(selectedDate - index, 'day')
                      .format('YYYY/MM/DD'),
                    tooltipDate: endDate
                      ?.clone()
                      .subtract(selectedDate - index, 'day')
                      .format('YYYY-MM-DD'),
                    value: randomIntFromInterval(15, 25),
                    type: 'Late tasks',
                  },
                ]),
              [],
            ),
    [selectedDate, endDate, selectRangeDate],
  );

  // TODO: data?.on_time_and_late_tasks_by_day?.data || []
  const taskData: ITaskItem[] = (
    data?.on_time_and_late_tasks_by_day?.data || []
  ).reduce(
    (rs: any[], item) =>
      rs.concat([
        {
          date: moment(item[0]).format('YYYY/MM/DD'),
          tooltipDate: moment(item[0]).format('MMM DD, YYYY'),
          value: item[1],
          type: 'On time tasks',
        },
        {
          date: moment(item[0]).format('YYYY/MM/DD'),
          tooltipDate: moment(item[0]).format('MMMM DD, YYYY'),
          value: item[2],
          type: 'Late tasks',
        },
      ]),
    [],
  );

  const driverUtilData: IDonutItem[] = [
    {
      value: data?.time_driver_spent_on_tasks_per_day || 0,
      quantity: data?.time_driver_spent_on_tasks_per_day || 0,
      type: 'In transit',
      convertedValue: getHourAndMinLabel(
        data?.time_driver_spent_on_tasks_per_day || 0,
      ),
    },
    {
      value: data?.time_driver_idle_per_day || 0,
      quantity: data?.time_driver_idle_per_day || 0,
      type: 'Idle',
      convertedValue: getHourAndMinLabel(data?.time_driver_idle_per_day || 0),
    },
  ];

  const taskAssignTitle = getHourMinSecLabel(data?.avg_time_to_assignment || 0);

  const taskAssignDescription = 'Average assign duration';

  const taskAssignData: IDonutItem[] = [
    {
      value: data?.tasks_without_reassignment || 0,
      quantity: data?.tasks_without_reassignment || 0,
      type: 'No re-assignment',
      convertedValue: `${data?.tasks_without_reassignment || 0}`,
      externalLink: `/tasks?tab=t_completed&reassigned_status=no_reassignment&date_gte=${date_gte}&length=${selectedDate}&timezone=${timezone}`,
    },
    {
      value: data?.tasks_with_reassignment || 0,
      quantity: data?.tasks_with_reassignment || 0,
      type: 'Reassigned tasks',
      convertedValue: `${data?.tasks_with_reassignment || 0}`,
      externalLink: `/tasks?tab=t_completed&reassigned_status=reassigned&date_gte=${date_gte}&length=${selectedDate}&timezone=${timezone}`,
    },
  ];

  const averageRating = data?.avg_rating || 0;
  const engagementRating = data?.current_engagement_rate || 0;
  const engagementRatingTrend = data?.engagement_trend?.trend || 0;

  const highReviews: IRatingItem[] = (data?.high_reviews || []).map((item) => ({
    id: item.id,
    feedback: item.feed_back,
    code: item.name,
    rating: item.rating,
    date: moment(item.updated_at).format('MMM DD'),
    searchQuery: !item.integration_type ? item.number : item.name,
  }));

  const lowReviews: IRatingItem[] = (data?.low_reviews || []).map((item) => ({
    id: item.id,
    feedback: item.feed_back,
    code: item.name,
    rating: item.rating,
    date: moment(item.updated_at).format('MMM DD'),
    searchQuery: !item.integration_type ? item.number : item.name,
  }));

  const storeLocations = useMemo(
    () =>
      data?.store_locations?.map(({ lat, lng }) => ({
        lat,
        lng,
      })) || [],
    [data],
  );

  const timeSlots = flatMap(
    data?.weekly_hourly_tasks || createWeekTimeSlots(),
    (timeSlots, weekDay) =>
      map(timeSlots, (task, timeSlot) => ({ weekDay, timeSlot, task })),
  );

  const renderOption = (option) => (
    <MenuItem
      key={option?.value}
      value={option?.value || ''}
      onClick={() => {
        if (option.value === 'custom') {
          setIsSelectCustom(true);
        }
      }}
      disableRipple
      sx={{
        'display': 'flex',
        'justifyContent': 'space-between',
        'color': themes.color.black,
        'whiteSpace': 'normal !important',
        'textOverflow': 'ellipsis',
        'overflow': 'hidden',
        'borderRadius': '15px',
        '&.MuiMenuItem-gutters': {
          '&:hover': {
            background: `${themes.bg.gray300}`,
          },
        },
        '&.Mui-selected': {
          'backgroundColor': themes.bg.midPurple,
          '&:focus': {
            backgroundColor: themes.bg.midPurple,
          },
        },
        ...(option?.value === 'custom' && {
          paddingRight: '4px',
        }),
      }}
    >
      {option?.label}{' '}
      {option?.value === 'custom' && (
        <KeyboardArrowRight
          sx={{ color: `${themes.color.violet900} !important` }}
        />
      )}
    </MenuItem>
  );

  const renderValue = (val) => {
    if (val === 'custom') {
      if (selectedDate === 1) return `${endDate?.format('MMM D, YYYY')}`;

      const startDate = endDate
        ?.clone()
        ?.local()
        ?.subtract(selectedDate - 1, 'days');

      const isSameYear = startDate?.year() === endDate?.year();

      return isSameYear
        ? `${startDate?.format('MMM D')} - ${endDate?.format('MMM D, YYYY')}`
        : `${startDate?.format('MMM D, YYYY')} - ${endDate?.format(
            'MMM D, YYYY',
          )}`;
    }

    const l = DateOption.find((o) => o.value === val)?.label;
    return l;
  };

  const renderHeaderExport = () => {
    const startDate = moment(date_gte);
    const endDate = moment(date_gte).add(selectedDate - 1, 'days');
    let formatted = '';
    const isSameYear = startDate?.year() === endDate?.year();

    formatted = isSameYear
      ? `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`
      : `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;

    return (
      <Box
        color={themes.color.violet700}
        fontSize={22}
        fontWeight='bold'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        pb={2}
        width='100%'
      >
        <span>Insights</span>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='end'
          alignItems='center'
          flex={1}
          gap={1.6}
          color='#33363D'
          fontSize={14}
          fontWeight={400}
        >
          {formatted}
        </Box>
      </Box>
    );
  };

  const renderFooterExport = (pageNumber = 1) => (
    <Box
      color='rgba(51, 54, 61, 0.4)'
      fontSize={14}
      fontWeight={400}
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      bottom={0}
    >
      <span>Powered by Breadstack</span>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='end'
        alignItems='center'
        flex={1}
        gap={1.6}
      >
        Page {pageNumber} of 2
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        id='insight-container'
        className='customized-scrollbar'
        sx={{
          backgroundColor: themes.bg.lightPurple,
          width: '100%',
          height: '100%',
          overflowX: 'auto',
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            px: '32px !important',
            py: 2.4,
            [themes.breakpoints.down('sm')]: {
              p: '16px !important',
            },
          }}
        >
          <Box
            color={themes.color.violet700}
            fontSize={22}
            fontWeight='bold'
            display='flex'
            alignItems='center'
            flexDirection='row'
            justifyContent='space-between'
            pb={2}
            width='100%'
            sx={{
              [themes.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: 1,
              },
            }}
          >
            <span>Insights</span>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='end'
              alignItems='center'
              flex={1}
              gap={1.6}
              sx={{
                [themes.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: 1,
                },
              }}
            >
              <Button
                noRounder
                buttonType='text'
                onClick={handleExport}
                sx={{
                  [themes.breakpoints.down('md')]: {
                    minWidth: 'unset !important',
                  },
                }}
                loading={loadingExport}
                disabled={isLoadingDashboard}
              >
                <Typography
                  variant='body1'
                  sx={{
                    cursor: 'pointer',
                    color: themes.color.violet500,
                    fontWeight: 500,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 0.4,
                  }}
                >
                  <img src={ExportIcon} alt='export-icon' /> Export to PDF
                </Typography>
              </Button>
              <div
                style={{
                  maxWidth: 250,
                  width: '100%',
                }}
                ref={anchorElRef}
              >
                <Select
                  formControlProps={{
                    sx: {
                      'maxWidth': 250,
                      '&>div': {
                        'background': 'white',
                        '&>div': {
                          color: `${themes.color.violet500} !important`,
                        },
                      },
                    },
                  }}
                  name='selectedDate'
                  onChange={(e) => {
                    if (e?.target?.value === 'custom') {
                      setIsSelectCustom(true);
                      setSelectRangeDate(true);
                    } else {
                      setSelectRangeDate(e?.target?.value === 1);
                      setIsSelectOption(true);
                      setSelectedDate(e?.target?.value as number);
                      setEndDate(moment());
                    }
                  }}
                  options={DateOption}
                  value={
                    DateOption.find((op) => op.value === selectedDate) &&
                    isSelectOption
                      ? selectedDate
                      : 'custom'
                  }
                  renderOption={renderOption}
                  renderValue={renderValue}
                />
              </div>
            </Box>
            {isSelectCustom && (
              <DateRangePicker
                open={isSelectCustom}
                anchorEl={anchorElRef.current}
                handleClose={() => setIsSelectCustom(false)}
                handleSelect={({
                  endDate: newEndDate,
                  selectedDate: newSelectDate,
                }) => {
                  setSelectRangeDate(true);
                  setSelectedDate(newSelectDate);
                  setEndDate(newEndDate);
                  setIsSelectCustom(false);
                  setIsSelectOption(false);
                }}
                timezone={timezone}
              />
            )}
          </Box>
          <Grid container spacing={1.6}>
            <Grid item xs={12}>
              <Summary items={summaryData} isLoading={false} />
            </Grid>
            <Grid item xs={12}>
              <Tasks
                isLoading={isLoading}
                items={isLoading ? emptyTaskList : taskData}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Donut
                title='Driver utilization rate'
                items={driverUtilData}
                colors={driverUtilColors}
                customHtml={() => '<div>Utilization</div>'}
                isLoading={
                  isLoading ||
                  (driverUtilData[0]?.value ?? 0) +
                    (driverUtilData[1]?.value ?? 0) ===
                    0
                }
                formatter={(data) => ({
                  name: ``,
                  value: `${getHourAndMinLabel(data?.value || 0)}`,
                })}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Donut
                title='Assignment efficiency'
                items={taskAssignData}
                colors={taskAssignColors}
                bigTitle={taskAssignTitle}
                bigDescription={taskAssignDescription}
                customHtml={() => '<div>Efficiency</div>'}
                isLoading={
                  isLoading ||
                  (taskAssignData[0]?.value ?? 0) +
                    (taskAssignData[1]?.value ?? 0) ===
                    0
                }
                formatter={(data) => ({
                  name: ``,
                  value: `${data?.value}`,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Rating
                engagementRating={engagementRating}
                engagementRatingTrend={engagementRatingTrend}
                isLoading={isLoading}
                averageRating={averageRating}
                highReviews={highReviews}
                lowReviews={lowReviews}
              />
            </Grid>
            <Grid item xs={12}>
              <DriverTable data={data?.driver_table_info || []} />
            </Grid>
            <Grid item xs={12}>
              <TimeSlots isLoading={isLoading} items={timeSlots} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                [themes.breakpoints.down('md')]: {
                  height: 400,
                },
              }}
            >
              {isNewHeatMap ? (
                <HitMapProvider
                  extraConfigs={{
                    mapTypeControl: true,
                    fullscreenControl: true,
                    maxZoom: 17,
                  }}
                >
                  <NewDropOffHitMap
                    data={data?.heat_map_data || []}
                    storeLocations={storeLocations}
                  />
                </HitMapProvider>
              ) : (
                <HitMapProvider>
                  <DropOffHitMap
                    data={data?.hit_map_data || null}
                    storeLocations={storeLocations}
                  />
                </HitMapProvider>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        id='insight-export'
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            opacity: 0,
            height: 1980,
            width: 1400,
            right: 0,
            p: 4.8,
            pb: 2.4,
            zIndex: -99,
            pointerEvents: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
          }}
          ref={exportPageOneRef}
        >
          <Box
            sx={{
              boxSizing: 'border-box',
            }}
          >
            {renderHeaderExport()}
            <Grid container spacing={1.6}>
              <Grid item xs={12}>
                <Summary items={summaryData} isLoading={false} />
              </Grid>
              <Grid item xs={12}>
                <Tasks
                  isLoading={isLoading}
                  items={isLoading ? emptyTaskList : taskData}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Donut
                  title='Driver utilization rate'
                  items={driverUtilData}
                  colors={driverUtilColors}
                  subTitleExport='Utilization'
                  customHtml={() => '<div>Utilization</div>'}
                  isLoading={
                    isLoading ||
                    (driverUtilData[0]?.value ?? 0) +
                      (driverUtilData[1]?.value ?? 0) ===
                      0
                  }
                  formatter={(data) => ({
                    name: `${data?.type}`,
                    value: `${getHourAndMinLabel(data?.value || 0)}`,
                  })}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Donut
                  title='Assignment efficiency'
                  items={taskAssignData}
                  colors={taskAssignColors}
                  bigTitle={taskAssignTitle}
                  bigDescription={taskAssignDescription}
                  subTitleExport='Efficiency'
                  customHtml={() => '<div>Efficiency</div>'}
                  isLoading={
                    isLoading ||
                    (taskAssignData[0]?.value ?? 0) +
                      (taskAssignData[1]?.value ?? 0) ===
                      0
                  }
                  formatter={(data) => ({
                    name: `${data?.type} `,
                    value: `${data?.value}`,
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Rating
                  engagementRating={engagementRating}
                  engagementRatingTrend={engagementRatingTrend}
                  isLoading={isLoading}
                  averageRating={averageRating}
                  highReviews={highReviews?.slice(0, 3)}
                  lowReviews={lowReviews?.slice(0, 3)}
                  scrollable={false}
                />
              </Grid>
              <Grid item xs={12}>
                <DriverTable data={data?.driver_table_info || []} exporting />
              </Grid>
            </Grid>
          </Box>
          {renderFooterExport()}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            opacity: 0,
            height: 1980,
            width: 1400,
            right: 0,
            p: 4.8,
            pb: 2.4,
            zIndex: -99,
            pointerEvents: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
          }}
          ref={exportPageTwoRef}
        >
          <Box
            sx={{
              boxSizing: 'border-box',
            }}
          >
            {renderHeaderExport()}
            <Grid container spacing={1.6}>
              <Grid item xs={12}>
                <TimeSlots isLoading={isLoading} items={timeSlots} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  '.gmnoprint, button, a': {
                    display: 'none !important',
                  },
                }}
              >
                {isNewHeatMap ? (
                  <HitMapProvider
                    extraConfigs={{
                      mapTypeControl: true,
                      fullscreenControl: true,
                      maxZoom: 17,
                    }}
                  >
                    <NewDropOffHitMap
                      data={data?.heat_map_data || []}
                      storeLocations={storeLocations}
                    />
                  </HitMapProvider>
                ) : (
                  <HitMapProvider>
                    <DropOffHitMap
                      data={data?.hit_map_data || null}
                      storeLocations={storeLocations}
                    />
                  </HitMapProvider>
                )}
              </Grid>
            </Grid>
          </Box>
          {renderFooterExport(2)}
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
