/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Table, { TColumn } from '~/components/common/Table/Table';
import { convertedSortParams } from '~/utils/common';
import { alertParams, showAlert } from '~/components/common/Alert';
import { IAlert } from '~/components/common/Alert/Alert';
import { useDeleteAddressMutation } from '~/services/api/addresss';
import { queryClient } from '~/lib/react-query';
import { IAddress } from '~/models/address';
import { fromQueryString } from '~/utils/queryString';
import themes, { styled } from '~/themes';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { ElementSelect } from '~/components/common/ElementSelect/ElementSelect';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { addressBookQueryKeys } from '../apis';

const EmptyStateData = [
  {
    name: 'Frequent Customer',
    phone_formatted: '(+1) 101-010-1010',
    address_1: '1010 Kingsway',
    city: 'Burnaby',
    state_name: 'British Columbia',
    postcode: 'V0H 0B0',
    email: 'test@example.com',
  },
  { id: '_noData' },
];

export const StyledEmptyStateContainer = styled('div')(({ theme }) => ({
  'position': 'absolute',
  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'center',
  'justifyContent': 'center',
  'padding': theme.spacing(2),
  'height': `calc(100% - ${theme.spacing(30)})`,
  'width': `calc(100% - ${theme.spacing(5)})`,
  'zIndex': 2,
  '& > h1': {
    marginTop: theme.spacing(3),
    fontSize: 14,
    paddingTop: 0,
    fontWeight: 400,
  },
  [theme.breakpoints.down('md')]: {
    '& > img:first-of-type': {
      maxWidth: '100%',
    },
  },
}));

interface AddressBookTableProps {
  data?: IAddress[];
  // eslint-disable-next-line no-unused-vars
  handleSort: (sortId: string, nextArrange: number) => void;
  loading: boolean;
  onEdit: (item: IAddress) => void;
  isEmptyState?: boolean;
  renderEmpty?: React.ReactNode;
}

export const AddressBookTable: React.FC<AddressBookTableProps> = ({
  data,
  handleSort,
  loading,
  onEdit,
  isEmptyState,
  renderEmpty,
}) => {
  const location = useLocation();

  const { sort, sort_by } = fromQueryString(location.search);
  const { mutate: deleteAddressMutation } = useDeleteAddressMutation({
    onSuccess: () => {
      showAlert({ ...alertParams.success, title: 'Deleted successfully' });
      queryClient.invalidateQueries(addressBookQueryKeys.getAddressBookList());
    },
  });

  const onDeleteAlert = useCallback((id: string) => {
    const params: IAlert = {
      ...alertParams.warning,
      title: 'Are you sure you want to delete?',
      description: 'The contact will be removed from address book.',
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: () => deleteAddressMutation(id),
    };
    showAlert(params);
  }, []);

  const renderAction = useCallback(
    (address: IAddress) => (
      <ElementSelect
        paperProps={{
          sx: {
            width: 160,
          },
        }}
        elementSelect={() => (
          <MoreHorizIcon sx={{ color: themes.color.violet900 }} />
        )}
        onChange={(o) => {
          if (o.value === 'edit') {
            onEdit(address);
          }
          if (o.value === 'delete') {
            onDeleteAlert(address.id);
          }
        }}
        options={[
          {
            label: 'Edit Contact',
            value: 'edit',
          },
          {
            label: 'Delete Contact',
            value: 'delete',
          },
        ]}
      />
    ),
    [],
  );

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'name',
        title: 'Name',
        sortable: true,
        cellStyle: {
          minWidth: 200,
        },
        renderComponent: (row) => (
          <ToolTipText text={row.name} maxLength={30} />
        ),
      },
      {
        id: 'phone_formatted',
        title: 'Phone No.',
        cellStyle: { minWidth: 120 },
      },
      {
        id: 'email',
        title: 'Email',
        cellStyle: { minWidth: 120 },
        renderComponent: (row) => (
          <ToolTipText
            text={row.email}
            maxLength={30}
            textNode={(v) => v || '-'}
          />
        ),
      },
      {
        id: 'address_1',
        title: 'Address',
        cellStyle: { minWidth: 120 },
        renderComponent: (row) => (
          <ToolTipText text={row.address_1} maxLength={30} />
        ),
      },
      {
        id: 'city',
        title: 'City',
        sortable: true,
        cellStyle: { minWidth: 120 },
      },
      {
        id: 'state_name',
        title: 'Province/State',
        sortable: true,
        cellStyle: { minWidth: 80 },
      },
      {
        id: 'postcode',
        title: 'Postal code',
        cellStyle: { minWidth: 80 },
      },
      {
        id: 'action',
        title: '',
        cellStyle: {
          minWidth: 50,
          textAlign: 'right',
        },
        renderComponent: renderAction,
      },
    ],
    [],
  );

  const renderEmptyState = useMemo(() => {
    if (isEmptyState) {
      return (
        <StyledEmptyStateContainer>{renderEmpty}</StyledEmptyStateContainer>
      );
    }
    return null;
  }, [isEmptyState, renderEmpty]);

  return (
    <Table
      columns={columns}
      dataProps={isEmptyState ? EmptyStateData : data}
      sortBy={convertedSortParams(sort, sort_by)}
      onSort={handleSort}
      loading={loading}
      noHoverInteraction
      striped={false}
      noResultText={null}
      customEmptyState={renderEmptyState}
      sxNoData={{ padding: '35px !important' }}
      headerCellSxProps={{
        color: '#8C95BA',
        fontSize: 12,
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
      }}
      cellSxProps={{
        paddingTop: '12px !important',
        paddingBottom: '12px !important',
      }}
      containerSxProps={{
        '& .MuiTableRow-root, .MuiTableCell-root': {
          border: 'none !important',
        },
        ...(isEmptyState
          ? {
              '& td': {
                'filter': 'grayscale(1)',
                'opacity': 0.25,
                '&:last-child': {
                  pointerEvents: 'none',
                },
              },
              '& tr': {
                height: 70,
              },
            }
          : {}),
        [themes.breakpoints.down('md')]: {
          '& td, th': {
            'minWidth': '60px !important',
            '&:nth-of-type(n + 3):nth-of-type(-n + 7)': {
              display: 'none',
            },
            '&:nth-of-type(4)': {
              display: 'table-cell !important',
            },
          },
        },
      }}
    />
  );
};
